<template>
  <v-dialog v-model="active" persistent>
    <v-card>
      <v-card-text>
        <span class="font-subhead-1 pre-line">
          คุณไม่สามารถสมัครกิจกรรมนี้ได้ เนื่องจากคุณไม่มาร่วมกิจกรรมจากครั้งก่อน
          (คุณสามารถสมัครกิจกรรมอีกครั้งได้ในวัน {{unbanAt}})
        </span>
      </v-card-text>
      <v-card-actions>
        <v-btn class="font-title" color="error" block large @click="$emit('update:active', false)">ปิด</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    active: Boolean,
    unbanAt: String
  }
}
</script>

<style scoped>
  .pre-line {
    white-space: pre-line;
  }
</style>