<template>
  <v-btn block :color="color" :disabled="disabled" :ripple="false">
    <span
      :class="
        `font-title font-weight-black pre-line ${
          disabled ? 'grey-text' : 'white--text'
        }`
      "
      >{{ text }}</span
    >
    <span class="grey--text"></span>
    <v-icon :class="disabled ? 'grey-text' : ''" color="white" light large>{{
      icon
    }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    color: String,
    icon: String,
    text: String,
    disabled: false
  }
};
</script>

<style scoped>
.pre-line {
  white-space: pre-line;
}
.grey-text {
  color: rgba(0, 0, 0, 0.7) !important;
}
.theme--light.v-btn.v-btn--disabled .v-icon,
.theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: rgba(0, 0, 0, 0.6) !important;
}
</style>
