import { render, staticRenderFns } from "./EventDetailDate.vue?vue&type=template&id=764b0a46&scoped=true"
import script from "./EventDetailDate.vue?vue&type=script&lang=js"
export * from "./EventDetailDate.vue?vue&type=script&lang=js"
import style0 from "./EventDetailDate.vue?vue&type=style&index=0&id=764b0a46&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "764b0a46",
  null
  
)

export default component.exports