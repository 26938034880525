<template>
  <v-container class="pa-0">
    <v-row class="d-flex flex-column align-center">
      <v-icon color="primary" large >mdi-comment-outline</v-icon>
      <span class="body-2">{{commentsCount}} ความคิดเห็น</span>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    postID: Number,
    comments: Number,
  },
  data() {
    return {
      commentsCount: this.comments
    }
  },
  methods: {

  }
}
</script>