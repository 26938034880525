<template>
  <div class="px-0 pt-2">
    <v-carousel
      v-model="carousel"
      height="244px"
    >
      <v-carousel-item
        :src="item.product.cover_image_url"
        @click="imageDialog = true"
      />
      <v-carousel-item
        v-for="(img,i) in item.image_urls"
        :key="i"
        :src="img.image_url"
        @click="imageDialog = true"
      />
    </v-carousel>
    <v-container class="px-5 pt-2 mb-16">
      <v-row>
        <v-col>
          <span class="headline font-weight-bold">
            <font color="#7AAE51">
              {{item.text}}
            </font>
          </span>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <span class="title">
            ราคา :
            <del v-if="item.product.price != item.product.sale_price || (item.product.verify_require && verifyStatus === 3)">{{item.product.price}} บาท</del>
            <font color="#EB5757">{{item.product.sale_price === 0 || (item.product.verify_require && verifyStatus === 3) ? 'ฟรี' : `${item.product.sale_price} บาท`}}</font>
          </span>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <!-- <span v-if="item.product.verify_require && verifyStatus === 3">
            ค่าจัดส่ง : <del>50 บาท <font color="#7F7F7F">(ชิ้นต่อไป +เพิ่ม 10บาท)</font></del><font color="#EB5757">&nbsp;ฟรี</font>
          </span>
          <span v-else>
            ค่าจัดส่ง : 50 บาท <font color="#7F7F7F">(ชิ้นต่อไป +เพิ่ม 10บาท)</font>
          </span> -->
          <span>ค่าจัดส่ง : 0 บาท</span>
        </v-col>
      </v-row>
      <v-divider class="my-1" />
      <v-row v-if="item.product.verify_require" dense>
        <v-col>
          <span class="font-weight-bold">หมายเหตุ :<br></span>
          <span style="white-space: pre-line;">{{item.product.note}}</span>
          <!-- <span>สำหรับผู้สูงอายุที่อายุ 60 ปีขึ้นไป รับหนังสือ ฟรี!! ไม่มีค่าจัดส่ง เพียงยืนยันตัวตนกับยังแฮปปี้</span> -->
        </v-col>
      </v-row>
      <v-row v-if="item.product.verify_require" dense>
        <div v-if="verifyStatus === 3" class="d-flex flex-row align-start my-2">
          <v-icon class="mr-2" color="#7AAE51">
            mdi-checkbox-marked-circle
          </v-icon>
          <span style="color:#7AAE51">
            ยืนยันตัวตนแล้ว
          </span>
        </div>
        <div v-if="verifyStatus === 2" class="d-flex flex-row align-start mt-2">
          <v-icon class="mr-2" color="#FF6161">
            mdi-close-circle-outline
          </v-icon>
          <span style="color:#FF6161">
            ขออภัยค่ะ คุณไม่ผ่านการยืนยันตัวตน<br>เนื่องจาก {{note}}
          </span>
        </div>
        <v-btn v-if="verifyStatus === 1" class="my-2" :ripple="false" color="#FF9900" outlined large block>
          <span class="font-weight-bold" :style="{'font-size': '16px','letter-spacing':'0.0125em'}">
            รอตรวจสอบยืนยันตัวตน ภายใน 24 ชั่วโมง
          </span>
        </v-btn>
        <v-btn v-if="verifyStatus === 0 || verifyStatus === 2" class="my-2" color="#EB5757" dark large block to="/account/verify" @click="$store.dispatch('app/savePreviousPath',$route.fullPath)">
          <span class="font-weight-bold" style="font-size: 16px">
            อายุ 60+ ยืนยันตัวตนรับฟรีได้ ที่นี่ !!
          </span>
        </v-btn>
      </v-row>
      <v-row dense>
        <v-col>
          <span class="font-weight-bold">
            รายละเอียดสินค้า :<br>
          </span>
          <span style="white-space: pre-line;">
            {{ item.product.description }}
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-footer style="background: #FFFFFF" elevation="4" padless app inset>
      <v-row class="my-3" justify="space-around">
        <v-btn class="ml-6" color="primary" outlined large width="40vw" @click="$router.push('/shop')">
          <span class="title">กลับ</span>
        </v-btn>
        <v-btn class="mr-6" color="primary" large width="40vw" :loading="purchaseBtnLoading" @click="CheckAddress()">
          <span class="title">เพิ่มลงตะกร้า</span>
        </v-btn>
      </v-row>
    </v-footer>
    <v-dialog v-model="updateProfileDialog" max-width="30rem">
      <v-card>
        <v-card-title class="d-flex justify-center align-center">
          <v-icon color="error" size="7rem">
            mdi-alert-circle
          </v-icon>
        </v-card-title>
        <v-card-text class="d-flex justify-center align-center py-2">
          <span class="font-title">กรุณาระบุชื่อและที่อยู่ให้ครบถ้วน</span>
        </v-card-text>
        <v-card-actions class="d-flex flex-column justify-center align-center">
          <v-btn class="font-title mb-1" outlined large block @click="$store.dispatch('app/savePreviousPath',$route.fullPath); $router.push('/account/update')">
            ตกลง
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addToCartDialog" max-width="30rem">
      <v-card>
        <v-card-title class="d-flex justify-center align-center">
          <span class="mt-4">เพิ่มสินค้าลงในตะกร้า</span>
        </v-card-title>
        <v-card-text class="d-flex flex-column justify-center align-center py-2">
          <div class="d-flex flex-row justify-center align-center">
            <v-btn icon color="error" :disabled="amount <= 0" @click="amount--">
              <v-icon large>mdi-minus-circle</v-icon>
            </v-btn>
            <div class="amount-box d-flex justify-center align-center mx-2">
              <span class="text-h6">
                {{ amount }}
              </span>
            </div>
            <!-- <v-btn icon color="primary" :disabled="item.product.limit === 0 ? false : (amount >= (item.product.limit - normalPurchasedAmount) + (verifyStatus == 3 ? (item.product.verify_limit - verifyPurchasedAmount) : 0) - getCartAmount) || amount >= item.product.left" @click="amount++"> -->
            <v-btn icon color="primary" :disabled="!canAddItem" @click="amount++">
              <v-icon large>mdi-plus-circle</v-icon>
            </v-btn>
          </div>
          <span class="mt-2">ระบุจำนวนที่ต้องการ</span>
          <span v-if="item.product.verify_require">(ผู้ที่ผ่านการยืนยันตัวตนแล้ว รับฟรี {{item.product.verify_limit}} เล่มต่อคน)</span>
        </v-card-text>
        <v-card-actions class="d-flex flex-column justify-center align-center pt-6">
          <v-btn
            class="font-title mb-3 mx-0"
            color="primary"
            large
            block
            :disabled="amount <= 0"
            @click="$store.dispatch('cart/addItem',{
              post_id: item.id,
              product_id: item.product.id,
              image_url: item.product.cover_image_url,
              text: item.text,
              amount: amount,
              left: item.product.left,
              limit: item.product.limit,
              verify_limit: item.product.verify_limit,
              price: item.product.price,
              sale_price: item.product.sale_price,
              verify_require: item.product.verify_require,
              normal_purchased: normalPurchasedAmount,
              verify_purchased: verifyPurchasedAmount
            });$router.push('/shop/cart')">
            ตกลง
          </v-btn>
          <v-btn class="font-title mb-1 mx-0" color="primary" outlined large block @click="addToCartDialog = false;amount = 0">
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="imageDialog" opacity="0.95">
    <div class="d-flex flex-row justify-end mb-1">
      <v-btn icon @click="imageDialog = false">
        <v-icon x-large color="error">
          mdi-close
        </v-icon>
      </v-btn>
    </div>
    <v-img :src="previewImage" max-height="90vmin" max-width="90vmin" contain />
  </v-overlay>
  </div>
</template>

<script>
export default {
  middleware: 'authenticated',
  async asyncData({ $axios, query }) {
    let [item, verifyStatus] = await Promise.all([
      $axios.get(`/post/get/detail?post_id=${query.id}`),
      $axios.get(`/kyc`)
    ])
    return {
      item: item.data.data.post,
      verifyStatus: verifyStatus.data.data.status_id,
      note: verifyStatus.data.data.note
    }
  },
  head() {
    return {
      title: this.item.text,
      meta: [
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.item.product.cover_image_url
        }
      ]
    }
  },
  data() {
    return {
      carousel: 0,
      imageDialog: false,
      updateProfileDialog: false,
      purchaseBtnLoading: false,
      user: {},
      addToCartDialog: false,
      amount: 0,
      normalPurchasedAmount: 0,
      verifyPurchasedAmount: 0
    }
  },
  computed: {
    previewImage() {
      if(this.carousel > 0) return this.item.image_urls[this.carousel - 1].image_url
      else return this.item.product.cover_image_url
    },
    getCartAmount() {
      var amount = 0
      try{
        amount = this.$store.state.cart.items.filter((item) => {return item.post_id == this.item.id})[0].amount
      }finally {
        return amount
      }
    },
    canAddItem() {
      if(this.item.product.limit <= 0) {
        return true && (this.amount < this.item.product.left)
      } else {
        if (this.item.product.verify_require && this.verifyStatus === 3) {
          return (this.amount + this.getCartAmount + this.normalPurchasedAmount + this.verifyPurchasedAmount < this.item.product.limit + this.item.product.verify_limit) && (this.amount < this.item.product.left)
        } else {
          return (this.amount + this.getCartAmount + this.normalPurchasedAmount < this.item.product.limit) && (this.amount < this.item.product.left)
        }
      }
    }
  },
  methods: {
    async CheckAddress() {
      if(Object.keys(this.user).length === 0){
        this.purchaseBtnLoading = true
        let res = await this.$axios.get('/user/my-simple-profile')
        if(res.status === 200){
          this.user = res.data.data.user
        } else {
          alert('error')
        }
        this.purchaseBtnLoading = false
      }
      if(this.user.first_name === '' || this.user.last_name === '' || this.user.address === '' || this.user.city === '' || this.user.zipcode === ''){
        this.updateProfileDialog = true
      } else {
        let amount = await this.$axios.get(`/product/get/purchased-amount?product_id=${this.item.product.id}`) 
        if(amount.status === 200) {
          this.normalPurchasedAmount = amount.data.data.normal_purchased
          this.verifyPurchasedAmount = amount.data.data.verify_purchased
          this.amount = this.canAddItem ? 1 : 0
          this.addToCartDialog = true
        }
      }
    }
  },
}
</script>

<style scope>
  .amount-box{
    width: 7rem;
    height: 3rem;
    background: #FFFFFF;
    border: 1px solid #7AAE51;
    box-sizing: border-box;
    border-radius: 5px;
  }
</style>