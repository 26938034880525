<template>
  <v-container class="pa-0">
    <v-row class="d-flex flex-column align-center">
      <v-icon color="primary" large >mdi-share</v-icon>
      <span class="body-2">แชร์</span>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    postID: Number,
    url: String,
  }
}
</script>