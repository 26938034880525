<template>
  <div class="px-0 pt-2">
    <v-img :src="getImage" lazy-src="/blank_img.png" @error="onImageError()" />
    <v-container>
      <v-row class="px-3" justify="center" align="center">
        <v-col class="pb-0" cols="3" align="center">
          <EventDetailDate
            :day="getEventDateOnly()"
            :month="getEventShortMonth()"
          />
        </v-col>
        <v-col class="d-flex flex-column pl-0">
          <span class="subtitle-1 font-weight-bold">{{ post.text }}</span>
          <div class="d-flex flex-row align-center mt-1">
            <v-avatar size="2rem">
              <v-img :src="post.official.image_url" lazy-src="/blank_img.png" />
            </v-avatar>
            <span class="ml-2 mr-1 body-2">จัดโดย:</span>
            <span class="body-2">{{ officials }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0" align="end">
          <div class="d-flex flex-row align-center justify-end mt-2">
            <!-- <v-btn
              v-if="post.event.registration !== null &&
                post.event.registration.length !== 0 &&
                post.event.registration[0].registration_status_id === 20"
              class="mb-1 mr-1"
              color="primary"
              depressed
              outlined
              @click="noteDialog = true"
            >
              ดูรายละเอียดการเข้าร่วม
            </v-btn> -->
            <v-btn
              v-if="
                post.event.registration !== null &&
                  post.event.registration.length !== 0 &&
                  post.event.registration[0].registration_status_id !== 2 &&
                  post.event.registration[0].registration_status_id !== 4 &&
                  (post.event.registration[0].registration_status_id < 6 ||
                    (post.event.registration[0].registration_status_id >= 20 &&
                      post.event.registration[0].registration_status_id < 23))
              "
              class="mb-1"
              color="error"
              depressed
              outlined
              @click="cancelDialog = true"
            >
              ยกเลิกการสมัคร
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-divider />
      <v-row v-show="false">
        <v-col class="d-flex flex-row">
          <EventDetailLike
            :postID="post.id"
            :likes="post.sum_like"
            :isLike="post.is_like"
          />
          <EventDetailComment :postID="post.id" :comments="post.sum_comment" />
          <EventDetailShare :postID="post.id" :url="post.url" />
        </v-col>
      </v-row>
      <v-divider v-show="false" />
      <v-row justify="center" class="d-flex flex-column px-10 pt-6 pb-4">
        <EventDetailDescriptionList
          icon="mdi-calendar"
          :text="getEventDate()"
        />
        <EventDetailDescriptionList icon="mdi-clock" :text="getEventTime()" />
        <EventDetailDescriptionList
          v-if="post.event.point > 0 || post.event.time > 0"
          icon="mdi-alpha-p-circle"
          :text="getPointTime()"
        />
        <EventDetailDescriptionList
          icon="mdi-cash-multiple"
          :text="getPrice()"
        />
        <EventDetailDescriptionList icon="mdi-account" :text="getSeat()" />
        <div>
          <span @click="shouldRedirect()" class="body-2 font-weight-medium">
            สถานที่
            <br />
            <font color="#7BBB5E"
              >{{ post.event.event_location.name }}
            </font></span
          >
        </div>
      </v-row>
      <v-divider />
      <v-row class="d-flex flex-column px-10 py-4 mt-1">
        <span class="body-2 font-weight-medium">รายละเอียดเพิ่มเติม</span>
        <p>{{ post.event.description }}</p>
        <a :href="post.event.url">{{ post.event.url }}</a>
      </v-row>
      <v-bottom-navigation app horizontal grow height="5rem">
        <EventDetailRegisterButton
          v-if="
            post.event.registration === null ||
              post.event.registration.length === 0 ||
              post.event.registration[0].registration_status_id === 2
          "
          color="#7BBB5E"
          :disabled="!canRegister"
          :text="registerButtonText"
          :icon="registerButtonIcon"
          @click.native="registerDialog = true"
        />
        <EventDetailRegisterButton
          v-else-if="post.event.registration[0].registration_status_id === 1"
          color="primary"
          :text="
            post.event.event_type_id == 3 || post.event.event_type_id == 4
              ? 'ลื้งร่วมกิจกรรม'
              : 'ตั๋วเข้างาน'
          "
          icon="mdi-qrcode"
          @click.native="shouldGetTicket"
        />
        <EventDetailRegisterButton
          v-else-if="post.event.registration[0].registration_status_id === 3"
          color="blue"
          :text="getButtonPrice()"
          icon="mdi-cash"
          @click.native="payment = true"
        />
        <EventDetailRegisterButton
          v-else-if="post.event.registration[0].registration_status_id === 4"
          color="orange"
          text="กำลังตรวจสอบการชำระเงิน"
          icon="mdi-cash"
        />
        <EventDetailRegisterButton
          v-else-if="post.event.registration[0].registration_status_id === 5"
          color="blue"
          text="หลักฐานการชำระเงินไม่สมบูรณ์
          ส่งหลักฐานใหม่"
          icon="mdi-close"
          @click.native="payment = true"
        />
        <EventDetailRegisterButton
          v-else-if="post.event.registration[0].registration_status_id === 6"
          color="orange"
          text="กำลังดำเนินการคืนเงิน"
          icon="mdi-cash"
        />
        <EventDetailRegisterButton
          v-else-if="post.event.registration[0].registration_status_id === 8"
          color="error"
          text="ไม่ได้เข้าร่วม"
          icon="mdi-account-alert"
        />
        <EventDetailRegisterButton
          v-else-if="post.event.registration[0].registration_status_id === 11"
          color="error"
          text="ยกเลิกจัดกิจกรรม"
          icon="mdi-close"
        />
        <div
          v-else-if="
            post.event.registration[0].registration_status_id === 21 &&
              this.post.event.event_manual.announcement_date !==
                '0001-01-01T00:00:00Z'
          "
          class="d-flex flex-column align-center bg-orange"
        >
          <span class="white--text">
            ท่านได้ลงทะเบียนแล้ว
          </span>
          <span class="font-title font-weight-black white--text">
            โปรดตรวจสอบสิทธิ์หลังวันที่ {{ announcementDate }}
          </span>
        </div>
        <div
          v-else-if="
            post.event.registration[0].registration_status_id === 20 &&
              post.event.event_manual
          "
          class="d-flex flex-column align-center bg-green pt-3"
          @click="noteDialog = true"
        >
          <span class="text-h6 font-weight-bold white--text">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.58689 0C4.56101 0 3.71599 0.822678 3.71599 1.82143V8.9936L3.30689 8.57589L3.15098 8.44292C2.8041 8.10672 2.33456 7.91797 1.84509 7.91797C1.35563 7.91797 0.886086 8.10672 0.539208 8.44292C0.193876 8.78063 0 9.23775 0 9.71428C0 10.1908 0.193876 10.6479 0.539208 10.9856V11.0045L5.64551 15.9187L5.68417 15.9375L5.70351 15.9757C6.60092 16.638 7.69556 16.9976 8.82167 17H9.89431C10.5652 17.0014 11.2298 16.8737 11.85 16.6243C12.4701 16.375 13.0335 16.0088 13.5079 15.5469C13.9823 15.085 14.3583 14.5365 14.6144 13.9327C14.8704 13.3289 15.0015 12.6819 15 12.0287V7.28571C15 6.28696 14.1543 5.46428 13.1291 5.46428C12.8634 5.46428 12.6177 5.53532 12.3882 5.63489C12.1837 4.8456 11.4459 4.25 10.5759 4.25C10.0982 4.25 9.65983 4.43214 9.32868 4.72418C8.98776 4.42093 8.5432 4.25192 8.08141 4.25C7.86832 4.25259 7.6573 4.29121 7.45778 4.36414V1.82143C7.45778 0.822678 6.61276 0 5.58689 0ZM5.58689 1.21429C5.933 1.21429 6.21052 1.48446 6.21052 1.82143V8.5H7.45778V6.07142C7.45778 5.73446 7.7353 5.46428 8.08141 5.46428C8.42753 5.46428 8.70505 5.73446 8.70505 6.07142V8.5H9.95231V6.07142C9.95231 5.73446 10.2298 5.46428 10.5759 5.46428C10.9221 5.46428 11.1996 5.73446 11.1996 6.07142V8.5H12.5055V7.28571C12.5055 6.94875 12.783 6.67857 13.1291 6.67857C13.4746 6.67857 13.7527 6.94875 13.7527 7.28571V12.0293C13.7527 14.1325 12.0546 15.7857 9.89369 15.7857H8.82229C7.90867 15.7857 7.11915 15.4682 6.46371 14.9885L1.41603 10.1314C1.13852 9.86121 1.13852 9.56674 1.41603 9.29657C1.69417 9.02639 1.99601 9.02639 2.27353 9.29657L4.96325 11.9152V1.82143C4.96325 1.48446 5.24077 1.21429 5.58689 1.21429Z"
                fill="white"
              />
            </svg>
            {{
              (() => {
                if (
                  post.event.registration[0].registration_status_id === 20 &&
                  this.post.event.event_manual.announcement_date ==
                    "0001-01-01T00:00:00Z"
                )
                  return "คลิกเพื่อรับลิ้งกิจกรรม ที่นี่";
                return "คลิกเพื่อดูรายละเอียดการเข้าร่วม ที่นี่";
              })()
            }}
          </span>
        </div>
        <div
          v-else-if="post.event.registration[0].registration_status_id === 22"
          class="d-flex flex-column align-center bg-green"
          @click="confirmEventDialog = true"
        >
          <span class="white--text">
            ท่านได้สิทธิ์เข้าร่วมกิจกรรม
          </span>
          <span class="text-h6 font-weight-bold white--text">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.58689 0C4.56101 0 3.71599 0.822678 3.71599 1.82143V8.9936L3.30689 8.57589L3.15098 8.44292C2.8041 8.10672 2.33456 7.91797 1.84509 7.91797C1.35563 7.91797 0.886086 8.10672 0.539208 8.44292C0.193876 8.78063 0 9.23775 0 9.71428C0 10.1908 0.193876 10.6479 0.539208 10.9856V11.0045L5.64551 15.9187L5.68417 15.9375L5.70351 15.9757C6.60092 16.638 7.69556 16.9976 8.82167 17H9.89431C10.5652 17.0014 11.2298 16.8737 11.85 16.6243C12.4701 16.375 13.0335 16.0088 13.5079 15.5469C13.9823 15.085 14.3583 14.5365 14.6144 13.9327C14.8704 13.3289 15.0015 12.6819 15 12.0287V7.28571C15 6.28696 14.1543 5.46428 13.1291 5.46428C12.8634 5.46428 12.6177 5.53532 12.3882 5.63489C12.1837 4.8456 11.4459 4.25 10.5759 4.25C10.0982 4.25 9.65983 4.43214 9.32868 4.72418C8.98776 4.42093 8.5432 4.25192 8.08141 4.25C7.86832 4.25259 7.6573 4.29121 7.45778 4.36414V1.82143C7.45778 0.822678 6.61276 0 5.58689 0ZM5.58689 1.21429C5.933 1.21429 6.21052 1.48446 6.21052 1.82143V8.5H7.45778V6.07142C7.45778 5.73446 7.7353 5.46428 8.08141 5.46428C8.42753 5.46428 8.70505 5.73446 8.70505 6.07142V8.5H9.95231V6.07142C9.95231 5.73446 10.2298 5.46428 10.5759 5.46428C10.9221 5.46428 11.1996 5.73446 11.1996 6.07142V8.5H12.5055V7.28571C12.5055 6.94875 12.783 6.67857 13.1291 6.67857C13.4746 6.67857 13.7527 6.94875 13.7527 7.28571V12.0293C13.7527 14.1325 12.0546 15.7857 9.89369 15.7857H8.82229C7.90867 15.7857 7.11915 15.4682 6.46371 14.9885L1.41603 10.1314C1.13852 9.86121 1.13852 9.56674 1.41603 9.29657C1.69417 9.02639 1.99601 9.02639 2.27353 9.29657L4.96325 11.9152V1.82143C4.96325 1.48446 5.24077 1.21429 5.58689 1.21429Z"
                fill="white"
              />
            </svg>
            คลิกที่นี่ เพื่อยืนยันสิทธิ์
          </span>
        </div>
        <div
          v-else-if="post.event.registration[0].registration_status_id === 23"
          class="d-flex flex-column align-center bg-red"
        >
          <span class="text-subtitle-1 font-weight-bold white--text">
            ขออภัยค่ะ ท่านไม่ได้รับสิทธิ์เข้าร่วมกิจกรรมนี้
          </span>
          <span class="white--text">
            {{ post.event.registration[0].reject_note }}
          </span>
        </div>
        <EventDetailRegisterButton
          v-else
          color="primary"
          text="เข้าร่วมกิจกรรมแล้ว"
          icon="mdi-account-check"
        />
      </v-bottom-navigation>
    </v-container>
    <v-dialog v-model="loading" persistent :transition="false" fullscreen>
      <v-container fill-height class="loading">
        <v-layout column justify-center align-center>
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
            color="#B6E28A"
          ></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
    <EventDetailPayment
      v-if="
        post.event.registration !== null && post.event.registration.length !== 0
      "
      :active.sync="payment"
      :registrationStatusID.sync="
        post.event.registration[0].registration_status_id
      "
      :registrationEventID="post.event.registration[0].id"
      :name="post.text"
      :price="post.event.price"
      :official="post.official"
      :user="user"
    />
    <EventDetailBan :active.sync="ban" :unbanAt="getUnbanAtDateTime()" />
    <EventDetailTicket
      v-if="
        post.event.registration !== null &&
          post.event.registration.length !== 0 &&
          (post.event.registration[0].registration_status_id === 1 ||
            post.event.registration[0].registration_status_id === 20)
      "
      :active.sync="ticket"
      :registrationID="post.event.registration[0].id"
      :userID="post.event.registration[0].user_id"
      :title="post.text"
      :day="getEventDateOnly()"
      :month="getEventShortMonth()"
    />
    <v-dialog v-model="registerDialog" persistent max-width="90vmin">
      <v-card>
        <v-card-title class="d-flex justify-center align-center">
          <span class="font-title"
            ><font color="#7BBB5E">ลงทะเบียนร่วมกิจกรรม</font></span
          >
        </v-card-title>
        <v-card-text
          class="d-flex flex-column justify-center align-center py-2"
        >
          <span class="font-title">คุณต้องการลงทะเบียนกิจกรรม</span>
          <span class="font-title">{{ post.text }}</span>
        </v-card-text>
        <v-card-actions class="d-flex flex-column justify-center align-center">
          <v-btn
            class="font-title mb-1"
            color="primary"
            large
            block
            @click="register()"
          >
            ยืนยันลงทะเบียน
          </v-btn>
          <v-btn
            class="font-title ma-0"
            color="error"
            text
            large
            block
            @click="registerDialog = false"
          >
            ยกเลิก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cancelDialog" persistent max-width="90vmin">
      <v-card>
        <v-card-title class="d-flex justify-center align-center">
          <v-icon color="error" size="5rem">
            mdi-alert-circle-outline
          </v-icon>
        </v-card-title>
        <v-card-text class="d-flex justify-center align-center py-2">
          <span class="font-title">ต้องการยกเลิกการลงทะเบียน</span>
        </v-card-text>
        <v-card-actions class="d-flex flex-column justify-center align-center">
          <v-btn
            class="font-title mb-1"
            color="error"
            large
            block
            @click="cancelRegister()"
          >
            ตกลง
          </v-btn>
          <v-btn
            class="font-title ma-0"
            text
            large
            block
            @click="cancelDialog = false"
          >
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="questionDialog"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <div class="d-flex flex-column py-4">
          <v-btn
            icon
            color="error"
            class="align-self-end pr-4"
            @click="questionDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <span class="text-h6 font-weight-bold px-8 pb-6"
            >กรุณาตอบคำถามประกอบการสมัครกิจกรรม {{ this.post.text }}</span
          >
          <v-divider />
          <div
            v-for="(question, index) in questions"
            :key="index"
            class="px-8 py-6 d-flex flex-column"
          >
            <span class="text-h6 font-weight-bold">
              <font color="#7AAE51"> ข้อที่ {{ index + 1 }}: </font>
            </span>
            <v-img
              v-if="question.image_url"
              :src="question.image_url"
              lazy-src="/blank_img.png"
              class="align-self-center"
              contain
              width="80%"
            />
            <span class="text-h6 font-weight-medium">
              {{ question.question }}
            </span>
            <v-text-field
              v-if="question.answer_type_id === 1"
              v-model="answers[index].answer"
              placeholder="คำตอบ"
              class="my-2"
              single-line
              outlined
              hide-details
            />
            <!-- empty ref for mapping {{index}} -->
            <input
              v-show="false"
              type="file"
              ref="answerImageRef"
              accept="image/*"
              @change="uploadPicSelected(index)"
            />
            <input
              v-show="false"
              type="file"
              ref="answerVideoRef"
              accept="video/*"
              @change="uploadVidSelected(index)"
            />
            <!-- empty ref for mapping {{index}} -->
            <!--------------------------------------------------------------- VVV การอัพโหลดรูปภาพ VVV --------------------------------------------------------------->
            <div
              v-if="answers[index].answer && question.answer_type_id === 2"
              class="mt-4"
              style="width:100%;border:1px dashed #2BA44F;border-radius:8px;display:flex;position:relative"
            >
              <img
                style="width:100%;border-radius:8px"
                :src="answers[index].answer"
              />
              <div style="position:absolute;width:100%;bottom:0">
                <v-btn
                  block
                  color="rgba(0, 0, 0, 0.6)"
                  @click="uploadPicHandler(index)"
                  style="color:#fff"
                  height="48"
                >
                  <v-icon right color="#fff" class="mr-2">
                    mdi-image-outline </v-icon
                  >เปลี่ยนรูปภาพ</v-btn
                >
              </div>
            </div>
            <div v-if="question.answer_type_id === 2">
              <v-btn
                block
                color="primary"
                class="mt-2"
                @click="uploadPicHandler(index)"
                v-if="!answers[index].answer"
              >
                <v-icon right dark class="mr-2">
                  mdi-image-outline
                </v-icon>
                เพิ่มรูปภาพ</v-btn
              >
            </div>
            <!--------------------------------------------------------------- ^^^ การอัพโหลดรูปภาพ ^^^ --------------------------------------------------------------->
            <!--------------------------------------------------------------- VVV การอัพโหลดวีดีโอ VVV --------------------------------------------------------------->
            <div
              v-if="answers[index].answer && question.answer_type_id === 3"
              class="mt-4"
              style="width:100%;border:1px dashed #2BA44F;border-radius:8px;display:flex;position:relative"
            >
              <img
                src="/play-btn.png"
                style="position:absolute;top:50%;left:50%;transform:translateX(-22px)translateY(-44px)"
              />
              <video style="width:100%;" @click="videoStageChange(index)">
                <source :src="answers[index].answer" type="video/mp4" />
              </video>
              <div style="position:absolute;width:100%;bottom:0">
                <v-btn
                  block
                  color="rgba(0, 0, 0, 0.6)"
                  @click="uploadVidHandler(index)"
                  style="color:#fff"
                  height="48"
                >
                  <v-icon right color="#fff" class="mr-2">
                    mdi-image-outline </v-icon
                  >เปลี่ยนวิดีโอ</v-btn
                >
              </div>
            </div>
            <div v-if="question.answer_type_id === 3">
              <v-btn
                block
                color="primary"
                class="mt-2"
                @click="uploadVidHandler(index)"
                v-if="!answers[index].answer"
              >
                <v-icon right dark class="mr-2">
                  mdi-image-outline
                </v-icon>
                เพิ่มวิดีโอ</v-btn
              >
            </div>
            <!--------------------------------------------------------------- ^^^ การอัพโหลดวีดีโอ ^^^ --------------------------------------------------------------->
            <div
              v-if="
                question.answer_type_id === 4 || question.answer_type_id === 5
              "
            >
              <v-radio-group
                v-model="
                  answers[
                    answers.findIndex(
                      answer => answer.question_id === question.id
                    )
                  ].choice_id
                "
              >
                <v-card
                  v-for="(choice, i) in question.choices"
                  :key="i"
                  elevation="0"
                  outlined
                  class="pa-4 my-2"
                >
                  <v-radio :value="choice.id">
                    <template v-slot:label>
                      <span class="text-subtitle-1 font-weight-medium">
                        <font color="#222222">
                          {{ choice.name }}
                        </font>
                      </span>
                    </template>
                  </v-radio>
                </v-card>
                <v-card
                  v-if="question.answer_type_id === 5"
                  elevation="0"
                  outlined
                  class="pa-4 my-2"
                >
                  <v-radio :value="-1">
                    <template v-slot:label>
                      <span class="text-subtitle-1 font-weight-medium">
                        <font color="#222222">
                          อื่น ๆ
                        </font>
                      </span>
                    </template>
                  </v-radio>
                </v-card>
                <v-text-field
                  v-show="
                    question.answer_type_id === 5 &&
                      answers[
                        answers.findIndex(
                          answer => answer.question_id === question.id
                        )
                      ].choice_id === -1
                  "
                  v-model="
                    answers[
                      answers.findIndex(
                        answer => answer.question_id === question.id
                      )
                    ].answer
                  "
                  placeholder="โปรดระบุ"
                  class="my-2"
                  single-line
                  outlined
                  hide-details
                  autofocus
                />
              </v-radio-group>
            </div>
          </div>
          <v-btn
            class="align-self-center"
            color="primary px-8"
            width="85%"
            large
            :disabled="$v.$invalid"
            @click="answerAndRegister()"
          >
            <span class="text-h6 font-weight-bold">
              ส่งคำตอบ
            </span>
          </v-btn>
        </div>
      </v-card>
      <v-dialog v-model="answerConfirmDialog" persistent>
        <v-card>
          <div
            class="d-flex flex-column py-4 align-center text-body-1 font-weight-medium"
            :style="{ 'background-color': '#EFF2EB' }"
          >
            <v-btn
              icon
              color="error"
              class="align-self-end pr-4"
              @click="
                answerConfirmDialog = false;
                refresh();
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <span
              class="text-h6 font-weight-bold mt-10"
              :style="{ color: '#5B7B45' }"
              >ได้รับข้อมูลการสมัครเรียบร้อยแล้ว</span
            >
            <span class="mt-8">ประกาศผลผู้มีสิทธิ์เข้าร่วม</span>
            <span>กิจกรรมและยืนยันสิทธิ์ ผ่านทาง LineOA</span>
            <span class="mb-12"
              >ในวันที่ {{ announcementDate }} - {{ confirmationDueDate }}</span
            >
          </div>
          <v-card-actions class="d-flex flex-column align-center py-4">
            <span>กรุณาเพิ่มเพื่อนทางไลน์ @YoungHappy</span>
            <div class="d-flex flex-row align-center mt-2">
              <v-img src="/app_logo.png" contain width="48px" />
              <v-btn
                class="ml-4 px-10 text-h6"
                rounded
                large
                :style="{ 'background-color': '#5B7B45' }"
                dark
                href="https://page.line.me/yvq3767w"
                target="_blank"
              >
                Add Friend
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
    <v-dialog
      v-if="
        post.event.event_manual &&
          post.event.registration != null &&
          post.event.registration.length > 0
      "
      v-model="confirmEventDialog"
    >
      <v-card>
        <v-card-title>
          <span :style="{ color: '#7BBB5D' }">ยืนยันสิทธิ์เข้าร่วมกิจกรรม</span>
          <v-spacer />
          <v-btn
            icon
            color="error"
            class="align-self-end"
            @click="confirmEventDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row justify="center" align="center">
              <v-col cols="3" align="center">
                <EventDetailDate
                  :day="getEventDateOnly()"
                  :month="getEventShortMonth()"
                />
              </v-col>
              <v-col class="d-flex flex-column">
                <span class="font-title">{{ post.text }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="font-subhead-1 grey--text py-0 px-4">
                <span>{{
                  post.event.registration[0].confirmation_note ||
                    post.event.event_manual.confirmation_note
                }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-8 pb-8">
          <v-btn
            class="text-h6 font-weight-bold"
            :style="{ 'background-color': '#FF5C00' }"
            dark
            block
            large
            @click="registerConfirm()"
          >
            กดยืนยันสิทธิ์ ที่นี่
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="
        post.event.event_manual &&
          post.event.registration != null &&
          post.event.registration.length > 0
      "
      v-model="noteDialog"
      fullscreen
      persistent
    >
      <v-card class="d-flex flex-column align-center">
        <v-icon class="mt-16" color="primary" size="5rem"
          >mdi-check-circle</v-icon
        >
        <span class="text-h6 font-weight-bold"
          >ยืนยันการเข้าร่วมกิจกรรมแล้ว</span
        >
        <span class="mt-4 font-subhead-1 grey--text py-0 px-4">{{
          post.event.registration[0].confirmation_note ||
            post.event.event_manual.confirmation_note
        }}</span>
        <span
          class="mt-4 mb-4 text-h5 font-weight-medium"
          :style="{ 'white-space': 'pre-line' }"
          >{{
            post.event.registration[0].note || post.event.event_manual.note
          }}</span
        >
        <v-btn
          class="px-6 py-5"
          width="15rem"
          :style="{ 'background-color': '#FF5C00' }"
          :href="post.event.registration[0].url || post.event.event_manual.url"
          target="_blank"
          rounded
          depressed
          large
          dark
          @click="noteDialog = false"
        >
          <svg
            class="mr-2"
            width="15"
            height="15"
            viewBox="0 0 15 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.58689 0C4.56101 0 3.71599 0.822678 3.71599 1.82143V8.9936L3.30689 8.57589L3.15098 8.44292C2.8041 8.10672 2.33456 7.91797 1.84509 7.91797C1.35563 7.91797 0.886086 8.10672 0.539208 8.44292C0.193876 8.78063 0 9.23775 0 9.71428C0 10.1908 0.193876 10.6479 0.539208 10.9856V11.0045L5.64551 15.9187L5.68417 15.9375L5.70351 15.9757C6.60092 16.638 7.69556 16.9976 8.82167 17H9.89431C10.5652 17.0014 11.2298 16.8737 11.85 16.6243C12.4701 16.375 13.0335 16.0088 13.5079 15.5469C13.9823 15.085 14.3583 14.5365 14.6144 13.9327C14.8704 13.3289 15.0015 12.6819 15 12.0287V7.28571C15 6.28696 14.1543 5.46428 13.1291 5.46428C12.8634 5.46428 12.6177 5.53532 12.3882 5.63489C12.1837 4.8456 11.4459 4.25 10.5759 4.25C10.0982 4.25 9.65983 4.43214 9.32868 4.72418C8.98776 4.42093 8.5432 4.25192 8.08141 4.25C7.86832 4.25259 7.6573 4.29121 7.45778 4.36414V1.82143C7.45778 0.822678 6.61276 0 5.58689 0ZM5.58689 1.21429C5.933 1.21429 6.21052 1.48446 6.21052 1.82143V8.5H7.45778V6.07142C7.45778 5.73446 7.7353 5.46428 8.08141 5.46428C8.42753 5.46428 8.70505 5.73446 8.70505 6.07142V8.5H9.95231V6.07142C9.95231 5.73446 10.2298 5.46428 10.5759 5.46428C10.9221 5.46428 11.1996 5.73446 11.1996 6.07142V8.5H12.5055V7.28571C12.5055 6.94875 12.783 6.67857 13.1291 6.67857C13.4746 6.67857 13.7527 6.94875 13.7527 7.28571V12.0293C13.7527 14.1325 12.0546 15.7857 9.89369 15.7857H8.82229C7.90867 15.7857 7.11915 15.4682 6.46371 14.9885L1.41603 10.1314C1.13852 9.86121 1.13852 9.56674 1.41603 9.29657C1.69417 9.02639 1.99601 9.02639 2.27353 9.29657L4.96325 11.9152V1.82143C4.96325 1.48446 5.24077 1.21429 5.58689 1.21429Z"
              fill="white"
            />
          </svg>
          <font class="text-h6">กดปุ่มนี้ !</font>
        </v-btn>
        <v-spacer />
        <!-- <v-btn class="ma-8 text-subtitle-1 font-weight-black" color="primary" large width="80%" dark @click="noteDialog = false">
          ปิดหน้านี้
        </v-btn> -->
      </v-card>
    </v-dialog>
    <!-- answer video viewer -->
    <v-dialog
      v-model="videoViewer"
      @click:outside="videoCloseHandler"
      content-class="modalreset"
    >
      <video v-if="videoViewer" controls ref="videoPlayerRef">
        <source :src="currentVideo" />
      </video>
    </v-dialog>
  </div>
</template>

<script>
import EventDetailLike from "~/components/EventDetailLike";
import EventDetailComment from "~/components/EventDetailComment";
import EventDetailShare from "~/components/EventDetailShare";
import EventDetailDescriptionList from "~/components/EventDetailDescriptionList";
import EventDetailDate from "~/components/EventDetailDate";
import EventDetailRegisterButton from "~/components/EventDetailRegisterButton";
import EventDetailPayment from "~/components/EventDetailPayment";
import EventDetailBan from "~/components/EventDetailBan";
import EventDetailTicket from "~/components/EventDetailTicket";
import itemVue from "../shop/item.vue";
export default {
  async asyncData({ $axios, route, store, redirect }) {
    try {
      if (store.state.authenticated.authenticated) {
        let [user, post] = await Promise.all([
          $axios.get("/user/my-profile?community_id=1"),
          $axios.get(`/post/get/detail?post_id=` + `${route.query.post_id}`)
        ]);

        if (post.data.data.post.post_type_id !== 2) redirect("/event");

        const userData = await $axios.get("/user/my-profile?community_id=1");
        const {
          display_name,
          first_name,
          gender,
          date_of_birth,
          city,
          zipcode,
          ...props
        } = userData.data.data.user;

        const required = [
          display_name,
          first_name,
          gender,
          date_of_birth
        ].filter(i => !i);
        if (required.length > 0) {
          if (store.state.app.isRegisterState) {
            redirect(`/account/update?post_id=${route.query.post_id}`);
          } else {
            redirect("/account/update");
          }
        }

        return {
          user: user.data.data.user,
          post: post.data.data.post
        };
      } else {
        return $axios
          .get(`/post/get/detail/no-auth?post_id=` + `${route.query.post_id}`)
          .then(res => {
            if (res.data.data.post.post_type_id !== 2) redirect("/event");
            return { post: res.data.data.post };
          });
      }
    } catch (err) {
      console.log(err);
      alert("Error");
      redirect("/event");
      return { post: null };
    }
  },
  head() {
    return {
      title: this.post.text,
      meta: [
        {
          hid: "og:image",
          property: "og:image",
          content: this.post.image_urls.length
            ? this.post.image_urls[0].image_url
            : "/blank_img.png"
        }
      ]
    };
  },
  components: {
    EventDetailLike,
    EventDetailComment,
    EventDetailShare,
    EventDetailDescriptionList,
    EventDetailDate,
    EventDetailRegisterButton,
    EventDetailPayment,
    EventDetailBan,
    EventDetailTicket
  },
  data() {
    return {
      failedImageLoad: false,
      day: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"],
      month: [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม"
      ],
      shortMonth: [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค",
        "พ.ย.",
        "ธ.ค."
      ],
      registerButtonText: "ลงทะเบียน",
      registerButtonIcon: "mdi-plus-circle",
      registed: false,
      loading: false,
      payment: false,
      ban: false,
      ticket: false,
      registerDialog: false,
      cancelDialog: false,
      questions: [],
      questionDialog: false,
      radioGroup: "",
      answers: [],
      answerConfirmDialog: false,
      confirmEventDialog: false,
      noteDialog: false,
      videoViewer: false,
      currentVideo: "",
      uploadFormats: []
    };
  },
  created() {
    if (this.$store.state.app.isRegisterState) {
      this.register();
      this.$store.dispatch("app/setIsRegisterState", false);
    }
  },
  mounted() {
    try {
      if (this.post.event.registration[0].registration_status_id === 1) {
        if (this.post.event.event_type_id == 3) return;
        if (this.post.event.event_type_id == 4) return;

        this.ticket = true;
      }
    } catch (e) {}
  },
  computed: {
    getImage() {
      if (!this.post.image_urls.length) return "/blank_img.png";
      return this.post.image_urls[0].image_url;
    },
    canRegister() {
      const now = new Date();
      const pass =
        now.getTime() >= new Date(this.post.event.event_start).getTime();
      const opening = new Date(this.post.event.register_open) <= now;
      const notClosing = now <= new Date(this.post.event.register_close);
      const aviableSeat =
        this.post.event.seat != 0
          ? this.post.event.register_count < this.post.event.seat
          : true;

      if (!opening) {
        const registerOpen = new Date(this.post.event.register_open);
        this.registerButtonText = `เปิดรับสมัครวันที่ \n${registerOpen.getDate()} ${
          this.shortMonth[registerOpen.getMonth()]
        } ${registerOpen.getFullYear() +
          543} เวลา ${registerOpen.getHours()}:${(registerOpen.getMinutes() < 10
          ? "0"
          : "") + registerOpen.getMinutes()} น.`;
        this.registerButtonIcon = "mdi-clock-alert-outline";
      } else if (pass) {
        this.registerButtonText = "กิจกรรมผ่านไปแล้ว";
        this.registerButtonIcon = "mdi-close-circle-outline";
      } else if (!aviableSeat) {
        this.registerButtonText = "รับสมัครเต็มจำนวนแล้ว";
        this.registerButtonIcon = "mdi-account-alert-outline";
      } else if (!notClosing) {
        this.registerButtonText = "ปิดรับสมัครแล้ว";
        this.registerButtonIcon = "mdi-clock-alert-outline";
      } else {
        (this.registerButtonText = "ลงทะเบียน"),
          (this.registerButtonIcon = "mdi-plus-circle");
      }

      return opening && notClosing && aviableSeat;
    },
    announcementDate() {
      const announcementDate = new Date(
        this.post.event.event_manual?.announcement_date
      );
      return `${announcementDate.getDate()} ${
        this.shortMonth[announcementDate.getMonth()]
      } ${announcementDate.getFullYear() + 543}`;
    },
    confirmationDueDate() {
      const dueDate = new Date(
        this.post.event.event_manual?.confirmation_due_date
      );
      return `${dueDate.getDate()} ${
        this.shortMonth[dueDate.getMonth()]
      } ${dueDate.getFullYear() + 543}`;
    },
    officials() {
      if (this.post.post_official.length == 1)
        return `${this.post.post_official[0].officials.display_name}`;
      if (this.post.post_official.length > 1)
        return this.post.post_official
          .map(item => {
            return item.officials.display_name;
          })
          .join(" และ ");
      return this.post.official.display_name;
    }
  },
  methods: {
    onImageError() {
      this.failedImageLoad = true;
    },
    getEventDateOnly() {
      const date = new Date(this.post.event.event_start);
      return date.getDate();
    },
    getEventShortMonth() {
      const date = new Date(this.post.event.event_start);
      return this.shortMonth[date.getMonth()];
    },
    getEventDate() {
      const startDate = new Date(this.post.event.event_start);
      const endDate = new Date(this.post.event.event_end);
      var date = `${this.day[startDate.getDay()]} ที่ ${startDate.getDate()} ${
        this.month[startDate.getMonth()]
      } ${startDate.getFullYear() + 543}`;
      if (startDate.getDate() != endDate.getDate())
        date += ` - ${this.day[endDate.getDay()]} ที่ ${endDate.getDate()} ${
          this.month[endDate.getMonth()]
        } ${endDate.getFullYear() + 543}`;
      return date;
    },
    getEventTime() {
      const startDate = new Date(this.post.event.event_start);
      const endDate = new Date(this.post.event.event_end);

      return `เวลา ${startDate.getHours()}:${(startDate.getMinutes() < 10
        ? "0"
        : "") + startDate.getMinutes()} น. -
       ${endDate.getHours()}:${(endDate.getMinutes() < 10 ? "0" : "") +
        endDate.getMinutes()} น.`;
    },
    getPointTime() {
      return `รับ ${
        this.post.event.point > 0
          ? `${this.post.event.point} คะแนน${
              this.post.event.time > 0 ? "," : ""
            }`
          : ""
      }
       ${
         this.post.event.time > 0 ? `จิตอาสา ${this.post.event.time} คะแนน` : ""
       }`;
    },
    getPrice() {
      return this.post.event.price === 0
        ? "ฟรี"
        : `${this.post.event.price} บาท/คน`;
    },
    getSeat() {
      return `${
        this.post.event.seat == 0
          ? "ไม่จำกัดจำนวน"
          : `รับ ${this.post.event.seat} คน`
      }  | เข้าร่วมแล้ว ${this.post.event.register_count} คน`;
    },
    getButtonPrice() {
      return `ชำระค่าลงทะเบียน ${this.post.event.price} บาท`;
    },
    getUnbanAtDateTime() {
      const date = new Date(this.post.event.unban_at);
      return `${this.day[date.getDay()]} ที่ ${date.getDate()} ${
        this.month[date.getMonth()]
      } ${date.getFullYear() +
        543} เวลา ${date.getHours()}:${date.getMinutes() + 1} น.`;
    },
    async register() {
      this.loading = true;
      if (!this.$store.state.authenticated.authenticated) {
        this.$store.dispatch(
          "app/savePreviousPath",
          `/event/detail?post_id=${this.post.id}`
        );
        this.$store.dispatch("app/setIsRegisterState", true);
        this.$router.push("/account/login");
      } else {
        if (this.post.event.can_register === false) {
          this.loading = false;
          this.ban = true;
        } else {
          this.$axios.get("/user/my-profile?community_id=1").then(res => {
            if (
              res.data.data.user.first_name == "" ||
              res.data.data.user.last_name == ""
            ) {
              alert("กรุณาอัปเดตข้อมูลผู้ใช้ให้ครบก่อนทำการลงทะเบียนกิจกรรม");
              this.$router.push("/account/update");
            } else {
              if (
                this.post.event.event_type_id === 2 ||
                (this.post.event.event_type_id === 4 &&
                  this.post.event.event_manual.announcement_date !=
                    "0001-01-01T00:00:00Z")
              ) {
                this.$axios
                  .get(`/question/event/get?event_id=${this.post.event.id}`)
                  .then(res => {
                    this.questions = res.data.data.event_questions
                      .map(event_question => {
                        return {
                          id: event_question.question.id,
                          event_question_id: event_question.id,
                          answer_type_id:
                            event_question.question.answer_type_id,
                          question: event_question.question.question,
                          image_url: event_question.question.image_url,
                          require_answer:
                            event_question.question.require_answer,
                          choices: event_question.question.choices?.sort(
                            (a, b) => a.id - b.id
                          )
                        };
                      })
                      .sort((a, b) => a.id - b.id);

                    res.data.data.event_questions
                      .sort((a, b) => a.id - b.id)
                      .forEach(event_question => {
                        this.answers.push({
                          event_question_id: event_question.id,
                          question_id: event_question.question.id,
                          answer: null,
                          choice_id: null
                        });
                      });

                    this.questionDialog = true;
                    this.registerDialog = false;
                    this.loading = false;
                  });
              } else {
                this.$axios
                  .post("/event/register", {
                    event_id: this.post.event.id
                  })
                  .then(res => {
                    window.location.reload();
                  })
                  .catch(() => {
                    alert("error.");
                    this.loading = false;
                  });
              }
            }
          });
        }
      }
    },
    async cancelRegister() {
      this.loading = true;
      this.$axios
        .put("/event/register/cancel", {
          event_id: this.post.event.id
        })
        .then(res => {
          window.location.reload();
        })
        .catch(() => {
          alert("error.");
          this.loading = false;
        });
    },
    async answerAndRegister() {
      this.loading = true;

      const { uid } = this.$fire.auth.currentUser;
      const uploadContent = this.answers.map((item, idx) => {
        if (item?.answer?.slice(0, 4) === "data") {
          return new Promise(resolve => {
            const firestore = this.$fire.storage.ref(
              `/events/${this.post.event.id}/${uid}/${idx}`
            );
            firestore.putString(item.answer, "data_url").then(snapshot => {
              firestore.getDownloadURL().then(url => {
                item.answer = url;
                resolve();
              });
            });
          });
        }
      });

      await Promise.all(uploadContent);

      this.$axios
        .post("/event/register/answer", {
          event_id: this.post.event.id,
          answers: this.answers.map(answer => {
            if (answer.choice_id === -1) {
              answer.choice_id = null;
            }
            return answer;
          })
        })
        .then(() => {
          this.answerConfirmDialog = true;
        });
    },
    uploadPicHandler(index) {
      this.$refs.answerImageRef[index].click();
    },
    uploadPicSelected(index) {
      if (!this.$refs.answerImageRef[index].files[0]) return;
      const reader = new FileReader();
      const image = this.$refs.answerImageRef[index].files[0];
      reader.readAsDataURL(image);

      this.uploadFormats[index] = this.$refs.answerImageRef[
        index
      ].files[0].type.split("/")[1];

      reader.onload = () => {
        this.answers[index].answer = reader.result;
      };
    },
    getVideoDuration: file => {
      return new Promise((resolve, reject) => {
        const media = new Audio(file);
        media.onloadedmetadata = () => resolve(media.duration);
      });
    },
    uploadVidHandler(index) {
      this.$refs.answerVideoRef[index].click();
    },
    uploadVidSelected(index) {
      if (!this.$refs.answerVideoRef[index].files[0]) return;
      const reader = new FileReader();
      const video = this.$refs.answerVideoRef[index].files[0];

      this.uploadFormats[index] = this.$refs.answerVideoRef[
        index
      ].files[0].type.split("/")[1];

      this.answers[index].answer = "";
      reader.readAsDataURL(video);

      reader.onload = async () => {
        this.answers[index].answer = reader.result;
        const duration = await this.getVideoDuration(reader.result);
        if (duration >= 210) {
          this.answers[index].answer = "";
          return alert("กรุณาเลือกวีดีโอความยาวไม่เกิน 3 นาทีค่ะ");
        }
      };
    },
    async videoStageChange(index) {
      await new Promise(resolve => {
        setInterval(resolve(), 5000);
      });
      this.currentVideo = this.answers[index].answer;
      this.videoViewer = true;
    },
    videoCloseHandler() {
      this.$refs.videoPlayerRef.pause();
      this.currentVideo = "/";
    },
    refresh() {
      window.location.reload();
    },
    async registerConfirm() {
      this.loading = true;
      try {
        let res = await this.$axios.put("/event/register/confirm", {
          registration_event_id: this.post.event.registration[0].id
        });
        if (res.data.success) {
          this.noteDialog = true;
          this.confirmEventDialog = false;
          this.post.event.registration[0].registration_status_id = 20;
        }
      } catch (_) {
        alert("พบข้อผิดพลาด");
      } finally {
        this.loading = false;
      }
    },
    shouldRedirect() {
      if (
        this.post.event.event_type_id == 3 ||
        this.post.event.event_type_id == 4
      ) {
        if (this.post.event.event_location.description.slice(0, 4) == "http")
          return window.location.replace(
            this.post.event.event_location.description
          );
      }
    },
    isOnlineEvent() {
      return false;
    },
    shouldGetTicket() {
      if (this.post.event.event_type_id == 3)
        return window.location.replace(this.post.event.registration[0].url);
      if (this.post.event.event_type_id == 4)
        return window.location.replace(this.post.event.registration[0].url);
      this.ticket = true;
    }
  },
  validations() {
    return {
      answers: {
        $each: {
          ans(answer) {
            return answer.answer || (answer.choice_id && answer.choice_id > 0)
              ? true
              : false;
          }
        }
      }
    };
  },
  watch: {
    post(state) {}
  }
};
</script>

<style scoped>
.date-badges {
  border-radius: 5px;
  background: #000000de;
  height: 80%;
}
.loading {
  background-color: rgba(0, 0, 0, 0.3);
}
.bg-orange {
  padding-top: 3px;
  background-color: orange;
  width: 100vw;
}
.bg-red {
  padding-top: 3px;
  background-color: #f63f32;
  width: 100vw;
}
.bg-green {
  padding-top: 3px;
  background-color: #7bbb5d;
  width: 100vw;
}
</style>

<style>
.modalreset {
  padding: 0;
  margin: 0 !important;
  box-shadow: none;
}
</style>
