<template>
  <v-container class="pa-0">
    <v-row class="d-flex flex-column align-center">
      <v-icon color="red" large @click="likePost()">mdi-heart</v-icon>
      <span class="body-2">{{likesCount}} ถูกใจ</span>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    postID: Number,
    likes: Number,
    isLike: Boolean
  },
  data() {
    return {
      likesCount: this.likes
    }
  },
  methods: {
    likePost() {
      ! this.$store.state.authenticated.authenticated
        ? this.$router.push("/login")
        : isLike
          ? this.$axios.delete("/like/post",{
            post_id: postID
            })
            && this.likesCount--
          : this.$axios.post("/like/post",{
              post_id: postID
            })
            && this.likesCount++
    }
  },
}
</script>