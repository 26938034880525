<template>
  <div class="d-flex flex-row align-center pb-4">
    <v-icon large left>{{icon}}</v-icon>
    <span class="subtitle-1 font-weight-bold">{{text}}</span>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    text: String,
  }
}
</script>