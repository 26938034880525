<template>
  <v-container fluid>
    <p class="text-center title mt-4">กิจกรรมสำหรับผู้สูงอายุ</p>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-tabs centered grow>
          <v-tab>
            <span class="font-subhead-1">ใกล้จะถึง</span>
          </v-tab>
          <v-tab>
            <span class="font-subhead-1">ผ่านไปแล้ว</span>
          </v-tab>
          <!-- <v-tab>
          <span class="font-subhead-1">กิจกรรม</span>
        </v-tab>
        <v-tab>
          <span class="font-subhead-1">จิตอาสา</span>
        </v-tab> -->
          <v-tab-item>
            <EventAll :data="$store.state.event.available" :intersect="false" />
          </v-tab-item>
          <v-tab-item>
            <EventAll :data="$store.state.event.passed" :intersect="true" />
          </v-tab-item>
          <!-- <v-tab-item>
        <EventAll :data="$store.state.event.events"/>
        </v-tab-item>
        <v-tab-item>
        <EventAll :data="$store.state.event.volunteers"/>
        </v-tab-item> -->
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EventAll from "~/components/EventAll";

export default {
  async asyncData({ $axios, route, $route, store, redirect }) {
    if (store.state.authenticated.authenticated) {
      const userData = await $axios.get("/user/my-profile?community_id=1");
      const {
        display_name,
        first_name,
        gender,
        date_of_birth,
        city,
        zipcode,
        ...props
      } = userData.data.data.user;

      const required = [display_name, first_name, gender, date_of_birth].filter(
        i => !i
      );

      if (required.length > 0) {
        redirect("/account/update");
      }
    }
    if (store.state.event.available.length === 0) {
      let [availableEvent, passedEvent] = await Promise.all([
        $axios.get(
          store.state.authenticated.authenticated
            ? `/event/v2/get?community_id=` + `${route.query.community_id || 1}`
            : `/event/v2/get/no-auth?community_id=` +
                `${route.query.community_id || 1}`
        ),
        $axios.get(
          store.state.authenticated.authenticated
            ? `/event/v2/passed/get?offset=0&community_id=` +
                `${route.query.community_id || 1}`
            : `/event/v2/passed/get/no-auth?offset=0&community_id=` +
                `${route.query.community_id || 1}`
        )
      ]);
      // var events = []
      // var voluteers = []
      // allEvents.filter((event) => {
      //   event.categories.find((category) => {
      //     return category.category_id === 999
      //   })
      //     ? voluteers.push(event)
      //     : events.push(event)
      // })
      store.commit(
        "event/setAvailable",
        availableEvent.data.data.posts.filter(item => {
          if (item.event.event_type_id != 3) {
            return item;
            c;
          }
        })
      );
      // store.commit('event/setEvents', events)
      // store.commit('event/setVolunteers', voluteers)
      store.commit(
        "event/setPassed",
        passedEvent.data.data.posts.filter(item => {
          if (item.event.event_type_id != 3) {
            return item;
          }
        })
      );
    }
  },
  head() {
    return {
      title: "หน้าแรก"
    };
  },
  components: {
    EventAll
  }
};
</script>
