<template>
  <div :class="labelClass" class="text-center">
      <p class="subtitle-1 margin-top-twenty font-weight-bold"> <v-icon :dark="regisStatus == 1 || regisStatus == 3">{{icon}}</v-icon> {{text}} </p>
  </div>

</template>

<script>
export default {
  props: {
    backgroundColor: "",
    textColor: "",
    text: "",
    regisStatus: 0
  },
  data() {
    return {
      labelClass: "",
      icon: ""

    }
  },
  created() {
    if (this.regisStatus == 1) {
      this.labelClass = 'label-bg-success'
      this.icon = "mdi-checkbox-marked-circle"
    } else if  (this.regisStatus == 3) {
      this.labelClass = 'label-bg-payment'
      this.icon = "mdi-clock"
    } else if  (this.regisStatus == 4) {
      this.labelClass = 'label-bg-waiting'
      this.icon = "mdi-clock"
    }
  },
  methods: {
    
  }
}
</script>

<style scoped>
.label-bg-waiting {
    height: 40px;
    background-color: #F6F78B;
    color: #2F2F2FDE;
}

.label-bg-payment {
    height: 40px;
    background-color: #FF964A;
    color: #FFFFFFDE;
}

.label-bg-success {
    height: 40px;
    background-color: #7FBB55;
    color: #FFFFFFDE
}

.margin-top-twenty {
  padding-top: 5px;
}
</style>