<template>
  <div>
    <div v-for="(event,index) in orderByEventStart" :key="index" justify="center">
      <EventCard v-if="index === orderByEventStart.length - 4" :event="event" v-intersect="intersect  ? onIntersect : void 0" @click.native="$router.push(`/event/detail?post_id=${event.id}`)" />
      <EventCard v-else :event="event" @click.native="$router.push(`/event/detail?post_id=${event.id}`)" />
    </div>
    <div class="d-flex flex-column justify-center align-center my-6">
      <v-progress-circular v-if="intersect && loading" indeterminate color="primary" size="48" />
    </div>
  </div>
</template>

<script>
import EventCard from '~/components/EventCard'

export default {
  data() {
    return {
      loading: false
    }
  },
  components: {
    EventCard
  },
  props: {
    data: Array,
    intersect: Boolean
  },
  computed: {
    orderByEventStart() {
      return this.data.sort((a,b) => {
        return this.intersect ? new Date(b.event.event_start) - new Date(a.event.event_start) : new Date(a.event.event_start) - new Date(b.event.event_start)
      })
    }
  },
  methods: {
    async onIntersect (entries, observer) {
      if (entries[0].isIntersecting) {
        this.loading = true
        let res = await this.$axios.get(this.$store.state.authenticated.authenticated
            ? `/event/passed/get?offset=${this.orderByEventStart.length}&community_id=` + `${this.$route.query.community_id || 1}`
            : `/event/passed/get/no-auth?offset=${this.orderByEventStart.length}&community_id=` + `${this.$route.query.community_id || 1}`)
        console.log(res.data.data.posts);
        this.$store.commit('event/concatPassed', res.data.data.posts)
        this.loading = false
      }
    },
  },
}
</script>
