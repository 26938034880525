<template>
  <div>
    <v-list-item>
      <v-list-item-avatar size="50"><img :src="imgUrl"></v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title><h2>{{displayName}}</h2></v-list-item-title>
        <v-list-item-subtitle>{{createdAt}} <v-icon small>mdi-earth</v-icon> {{eventType}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item> 
  </div>
</template>

<script>

export default {
  props: {
    imgUrl: String,
    displayName: String,
    createdAt: String,
    eventType: String
  }
}
</script>