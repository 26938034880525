<template>
  <v-dialog v-model="active" persistent scrollable>
    <v-card color="#EEF2EB">
      <v-card-title>
        <v-icon class="mr-3" color="primary" large>mdi-qrcode</v-icon>
        <span class="font-title"
          ><font color="#7BBB5E">ตั๋วเข้าร่วมกิจกรรม</font></span
        >
        <v-spacer />
        <v-icon @click="$emit('update:active', false)">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-container class="pa-2">
          <v-row>
            <v-col cols="3">
              <EventDetailDate :day="day" :month="month" />
            </v-col>
            <v-col>
              <span class="font-title font-weight-bold text-black">
                {{ title }}
              </span>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-4">
            <span class="font-body-2"
              >กรุณานำ QR Code นี้แสดงต่อ Staff หน้างาน</span
            >
          </v-row>
          <v-row class="mt-4" justify="center">
            <qrcode
              :value="getQrValue()"
              :options="{
                errorCorrectionLevel: 'H',
                width: getQrWidth(),
                margin: 2
              }"
            ></qrcode>
          </v-row>
        </v-container>
      </v-card-text>
      <!-- <v-card-actions
        class="d-flex flex-column justify-center"
        style="background-color:white"
      >
        <span class="font-subhead-1 mt-2 mb-1"
          >ดาวน์โหลดแอปยังแฮปปี้เพื่อสะสมคะแนน</span
        >
        <div class="d-flex flex-row align-center mb-2">
          <v-img src="/app_logo.png" max-width="3rem" contain />
          <v-btn
            class="font-title white--text ml-4"
            href="https://yhp.page.link/?link=http://www.younghappy.com/&apn=org.younghappy.android&isi=1279819149&ibi=com.ios.younghappy.YoungHappy"
            target="_blank"
            color="#3087D1DE"
            min-width="12.5rem"
            large
            rounded
          >
            DOWNLOAD
          </v-btn>
        </div>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import EventDetailDate from "~/components/EventDetailDate";

export default {
  props: {
    active: Boolean,
    registrationID: Number,
    userID: Number,
    day: Number,
    month: String,
    title: String
  },
  components: {
    qrcode: VueQrcode,
    EventDetailDate
  },
  methods: {
    getQrValue() {
      return `YoungHappyEventTicket/eventRegistrationID=${this.registrationID}&userID=${this.userID}&communityID=1`;
    },
    getQrWidth() {
      if (process.client) {
        return document.documentElement.clientWidth * 0.6;
      }
      return 200;
    }
  }
};
</script>

<style scoped>
.text-black {
  color: rgba(0, 0, 0, 0.87);
}
</style>
