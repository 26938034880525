<template>
  <v-card class="ma-4" elevation="4" min-height="200">
    <v-row no-gutters>
      <v-col >
        <Header :imgUrl="imgUrl" :displayName="displayName" :createdAt="displayFullDate(this.event.created_at)" :eventType="getEventType()"/>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <div style="position: relative;">
        <v-img width="100%" contain :src="eventImage || '/blank_img.png'" lazy-src="/blank_img.png" @error="onImageError()"></v-img>
        <div class="event-square">
          <div class="centered">
            <h3 class="date-text"><p style="font-size: 24px;">{{new Date(showEvent.event.event_start).getDate()}}</p><br><p>{{month}}</p></h3>
          </div>
        </div>
        <div v-if="isShowStatus()">
          <EventStatus :backgroundColor="bgColor" :textColor="_textColor" :text="_text" :regisStatus="_regisStatus"/>
        </div>
      </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Header from '~/components/Header'
import EventDetail from '~/pages/event/detail'
import EventStatus from '~/components/RegisStatusLabel'

export default {
  data() {
    return {
      imgUrl: String,
      displayName: String,
      showEvent: {},
      eventImage: '',
      month: String,
      status: [
          { text: "", color: "#FF964A", textColor: "#FFFFFFDE", status: 0},
          { text: "ลงทะเบียนสำเร็จแล้ว", color: "#7FBB55", textColor: "#FFFFFFDE", status: 1},
          { text: "ยกเลิกกิจกรรมแล้ว", color: "#BEC6CC", textColor: "#FFFFFFDE", status: 2},
          { text: "รอแนบหลักฐานการชำระเงิน", color: "#FF964A", textColor: "#FFFFFFDE", status: 3},
          { text: "รอตรวจสอบการชำระเงิน", color: "#F6F78B", textColor: "#2F2F2FDE", status: 4},
          { text: "ยืนยันไม่ผ่าน", color: "#FA8072", textColor: "#FFFFFFDE", status: 5},
          { text: "รอตรวจสอบการคืนเงิน", color: "#85C1E9", textColor: "#FFFFFFDE", status: 6},
          { text: "ลงทะเบียนสำเร็จแล้ว", color: "#7FBB55", textColor: "#FFFFFFDE", status: 7},
          { text: "ไม่ได้เข้าร่วมกิจกรรม", color: "#FF3C25", textColor: "#FFFFFFDE", status: 8},
          { text: "ลงทะเบียนสำเร็จแล้ว", color: "#7FBB55", textColor: "#FFFFFFDE", status: 9},
          { text: "ลงทะเบียนสำเร็จแล้ว", color: "#7FBB55", textColor: "#FFFFFFDE", status: 10},
          { text: "กิจกรรมนี้ถูกยกเลิกแล้ว", color: "#EE8814", textColor: "#FFFFFFDE", status: 11},
        ],
      bgColor: String,
      _textColor: String,
      _text: String,
      _regisStatus: 0
    }
  },
  components: {
    Header,
    EventStatus
  },
  props: {
    event:{}
  },
  created() {
    let temp = this.event.official !== undefined ? this.event.official : this.event.user
    if (this.event != undefined) {
      this.showEvent = this.event
      try {
        this.eventImage = this.showEvent.image_urls[0].image_url
      }catch{
        this.eventImage = '/blank_img.png'
      }
      this.month = EventDetail.data().shortMonth[new Date(this.showEvent.event.event_start).getMonth()]
    }
    if (temp !== undefined) {
      this.imgUrl = temp.image_url,
      this.displayName = temp.display_name
    }
  },
  methods: {
    displayFullDate(date) {
      let temp = new Date(date)
      return temp.getDate() + " " + EventDetail.data().shortMonth[temp.getMonth()] + " เวลา " + temp.getHours() + ":" + temp.getMinutes() + " น."
    },
    isShowStatus() {
      if (this.event.event.registration !== null && this.event.event.registration.length > 0) {
        let registration = this.event.event.registration[0]
        let temp = this.status.filter( e => {
          return e.status == registration.registration_status_id
        })
        if (temp.length > 0) {
          this.bgColor = temp[0].color
          this._textColor = temp[0].textColor
          this._text = temp[0].text
          this._regisStatus = registration.registration_status_id
        }
        return (registration.registration_status_id == 1 || registration.registration_status_id == 3 || registration.registration_status_id == 4)
      }
      return false
    },
    getEventType() {
      return this.event.categories.filter(e => {
        return e.category_id == 999
      }).length > 0 ? "จิตอาสา" : "กิจกรรม"
    },
    onImageError() {
      this.eventImage = '/blank_img.png'
    },
  }
}
</script>

<style scoped>
.event-square {
  background-color: black;
  top: 16px;
  left: 16px;
  position: absolute;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  color: #A0CF77;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.date-text {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 0px;
  word-wrap: normal;
}
</style>