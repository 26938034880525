<template>
  <v-dialog v-model="active" persistent fullscreen transition="dialog-bottom-transition">
    <v-card>
      <div class="d-flex flex-column pa-8">
        <span class="font-title">{{name}}</span>
        <div class="d-flex flex-column pa-4 mt-2">
          <div class="d-flex flex-row align-center">
            <v-icon left large>mdi-numeric-1-circle</v-icon>
            <span class="font-title">โอนเงินผ่านบัญชีธนาคาร</span>
          </div>
          <v-container fluid>
            <v-row justify="center" align="center">
              <v-col cols="4" class="pa-0">
                <v-img :src="official.payment_account_qr" contain @click="qrZoom = true"></v-img>
              </v-col>
              <v-col>
                <div class="d-flex flex-column">
                  <div class="d-flex flex-row">
                    <v-img :src="official.bank.image_url" max-width="1.5rem" contain></v-img>
                    <span class="font-subhead-1 ml-1">{{official.bank.name}}</span>
                  </div>
                  <span class="font-caption">ชื่อบัญชี: {{official.payment_account_name}}</span>
                  <span class="font-caption">เลขที่บัญชี: {{official.payment_account_number}}</span>
                  <span class="font-caption">สาขา {{official.payment_account_branch}}</span>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <div class="d-flex flex-row grey-background pa-2">
            <span class="font-subhead-1">จำนวนเงิน</span>
            <v-spacer />
            <span class="font-subhead-1">{{price}} บาท</span>
          </div>
        </div>
        <v-divider />
        <div class="d-flex flex-column pa-4">
          <div class="d-flex flex-row align-center">
            <v-icon left large>mdi-numeric-2-circle</v-icon>
            <span class="font-title">แนบหลักฐานการโอนเงิน</span>
          </div>
          <span class="font-subhead-1">แนบสลิปการโอนเงิน หรือ รูปถ่ายบิลที่ชำระเงินแล้ว</span>
          <v-btn
          class="mt-2"
            v-if="!paymentSlipImage"
            color="primary"
            large depressed
            @click="$refs.fileUpload.click()"
          >
            <span class="font-title">แนบหลักฐาน</span>
            <v-icon large>mdi-image</v-icon>
          </v-btn>
          <input
            v-show="false"
            ref="fileUpload"
            type="file"
            accept="image/*"
            @change="onFileChange"
          />
          <v-img
            class="py-2"
            v-if="paymentSlipImage"
            :src="paymentSlipImage"
            max-height="1200px"
            contain
          />
          <v-btn
            v-if="paymentSlipImage"
            text
            :ripple="false"
            @click="$refs.fileUpload.click()"
          >
            <v-icon>mdi-pencil</v-icon>
            <span>แก้ไขรูป</span>
          </v-btn>
        </div>
        <v-divider />
        <div class="d-flex flex-column pa-4">
          <div class="d-flex flex-row align-center">
            <v-icon left large>mdi-numeric-3-circle</v-icon>
            <span class="font-title">รอการยืนยันการชำระเงิน</span>
          </div>
          <div class="text-center grey-background pa-2 mt-2">
          <span class="font-subhead-1">รอทีมงานตรวจสอบการโอนเงินและอนุมัติใน 24 ชม.</span>
        </div>
        </div>
        <v-btn
            class="mt-2"
            v-if="paymentSlipImage"
            color="primary"
            large depressed
            @click="UploadPaymentSlip()"
          >
            <span class="font-title">เสร็จสิ้น</span>
        </v-btn>
        <v-btn v-else class="font-subhead-1 mt-6" text @click="$emit('update:active', false)">
          <span>
            ชำระเงินภายหลัง >
          </span>
        </v-btn>
      </div>
    </v-card>
    <v-dialog v-model="loading" persistent :transition="false" fullscreen>
      <v-container fill-height class="loading">
        <v-layout column justify-center align-center>
          <v-progress-circular :value="progress" :size="70" :width="7" color="#B6E28A" rotate="-90"></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
    <v-dialog v-model="qrZoom">
      <v-img :src="official.payment_account_qr" contain></v-img>
    </v-dialog>
  </v-dialog>
</template>

<script>
export default {
  props:{
    registrationEventID: Number,
    active: false,
    name: String,
    price: Number,
    official: Object,
    registrationStatusID: Number,
    user: Object
  },
  data() {
    return {
      paymentSlipImage: null,
      progress: 0,
      loading: false,
      qrZoom: false,
    }
  },
  methods: {
    onFileChange(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.srcElement.files[0])
      reader.onload = () => {
        this.paymentSlipImage = reader.result
      }
    },
    async UploadPaymentSlip() {
      this.loading = true
      var storage = this.$fire.storage.ref().child(
        'users/' + this.user.new_user_id + '/payments/events/' + Date.now()
      )
      var uploadTask = storage.putString(this.paymentSlipImage, 'data_url')
      uploadTask.on(
        this.$fireModule.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          this.progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        error => {},
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            this.$axios
              .post('/event/payment/send-slip', {
                registration_event_id: this.registrationEventID,
	              slip_url: downloadURL
              })
              .then((res) => {
                if(res.status === 200) {
                  alert('Upload finished.')
                  this.$emit('update:registrationStatusID', 4)
                  this.$emit('update:active', false)
                }
              })
              .catch(() => {
                alert('Error. Please try again later.')
              })
              .finally(() => {
                this.loading = false
                this.progress = 0
              })
          })
        }
      )
    }
  },
}
</script>

<style scoped>
  .grey-background {
    background-color: #E8E8E8;
    border-radius: 5px;
  }
  .loading {
    background-color: rgba(0, 0, 0, 0.3);
  }
</style>