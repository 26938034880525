<template>
  <v-card color="#000000DE" flat max-width="15vmin">
    <v-card-text class="text-center pa-1">
      <span class="headline font-weight-black no-wrap">
        <font color="#7BBB5E">{{ day }}</font>
        <br />
      </span>
      <span class="subtitle-1 font-weight-bold no-wrap">
        <font color="#7BBB5E">{{ month }}</font>
      </span>
      <!-- <span class="no-wrap">{{day}}</span> -->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    day: Number,
    month: String
  }
};
</script>

<style scoped>
.no-wrap {
  white-space: nowrap;
}
</style>